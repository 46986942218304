import {
  FORGET_PASSWORD_SEND_EMAIL,
  GET_SINGLE_PRODUCT,
  HOMEPAGE_DATA_SUCCESS,
  HOMEPAGE_PRODUCTS_SUCCESS,
  SHOP_DATA_SUCCESS,
  UserLoginTypes,
  UserRegisterTypes,
  SET_LOGIN_LOADER,
  SET_REGISTER_LOADER,
  UPDATE_PROFILE,
  UPDATE_PASSWORD,
  SUBSCRIBE_EMAIL,
  OPT_VERIFICATION,
  CONTACT_US,
  UPLOAD_IMAGE,
  GUEST_USER_REGISTER_SUCCESS,
  GUEST_USER_REGISTER_LOADER,
  CREATE_NEW_AD_LOADER,
} from "../actions/types";
const INITIAL_STATE = {
  LoginLoading: false,
  registerLoading: false,
  guestUserRegisterLoading: false,
  listLoading: false,
  guestUserLoading: false,
  createNewAdLoading: false,
  deleteAdLoading: false,
  images: {},
  homeData: {},
  home_products: {},
  single_product: {},
  shop_data: {},
  userInfo: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HOMEPAGE_DATA_SUCCESS: {
      return {
        ...state,
        homeData: action.payload,
        listLoading: false,
      };
    }
    case HOMEPAGE_PRODUCTS_SUCCESS: {
      return {
        ...state,
        home_products: action.payload,
        listLoading: false,
      };
    }
    case GET_SINGLE_PRODUCT: {
      return {
        ...state,
        single_product: action.payload,
        listLoading: false,
      };
    }
    case SHOP_DATA_SUCCESS: {
      return {
        ...state,
        shop_data: action.payload,
        listLoading: false,
      };
    }
    case UserLoginTypes.USER_LOGIN_SUCCESS: {
      return {
        ...state,
        LoginLoading: false,
        userInfo: action.payload,
      };
    }
    case UserRegisterTypes.USER_REGISTER_SUCCESS: {
      return {
        ...state,
        registerLoading: false,
        userInfo: action.payload,
      };
    }
    case GUEST_USER_REGISTER_SUCCESS: {
      return {
        ...state,
        guestUserRegisterLoading: false,
        userInfo: action.payload,
      };
    }
    case GUEST_USER_REGISTER_LOADER: {
      return {
        ...state,
        guestUserRegisterLoading: true,
      };
    }
    case UPDATE_PROFILE: {
      return {
        ...state,
        userInfo: action.payload,
        listLoading: false,
      };
    }
    case UPDATE_PASSWORD: {
      return {
        ...state,
        userInfo: action.payload,
        listLoading: false,
      };
    }
    case SUBSCRIBE_EMAIL: {
      return {
        ...state,
        listLoading: false,
      };
    }
    case FORGET_PASSWORD_SEND_EMAIL: {
      return {
        ...state,
        listLoading: false,
      };
    }
    case OPT_VERIFICATION: {
      return {
        ...state,
        listLoading: false,
      };
    }
    case CONTACT_US: {
      return {
        ...state,
        listLoading: false,
      };
    }
    case SET_LOGIN_LOADER: {
      return {
        ...state,
        LoginLoading: true,
      };
    }
    case SET_REGISTER_LOADER: {
      return {
        ...state,
        registerLoading: true,
      };
    }
    case UPLOAD_IMAGE: {
      return {
        ...state,
        images: action.payload,
      };
    }
    case CREATE_NEW_AD_LOADER: {
      return {
        ...state,
        createNewAdLoading: true,
      };
    }
    default:
      return state;
  }
};
