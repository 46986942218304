import { Redirect, Route } from "react-router-dom";
import React from "react";
import { token } from "./../../utils/localStorage";
function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={() => (token ? children : <Redirect to="/login-register" />)}
    />
  );
}
export default PrivateRoute;
