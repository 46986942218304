import currencyReducer from "./currencyReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import Reducer from "./Reducer";
import AdReducer from "./AdsReducer";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  currencyData: currencyReducer,
  wishlistData: wishlistReducer,
  compareData: compareReducer,
  homePageData: Reducer,
  AdsData: AdReducer,
});

export default rootReducer;
