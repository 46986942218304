import {
  DELETE_AD_SUCCESS,
  GET_CREATE_AD_DATA,
  SET_GET_CREATE_DATA_LOADING,
  CREATE_NEW_AD_FAIL,
  GET_ALL_ADS,
  GET_CITY_DATA,
  GET_MODALS_DATA,
  DELETE_AD_LOADER,
  SET_PRODUCT_LOADING,
  GET_SINGLE_AD,
  SET_ADS_LOADING,
} from "../actions/types";
const INITIAL_STATE = {
  listLoading: false,
  actionLoading: false,
  getCreateAdsDataLoading: false,
  deleteAdLoading: false,
  ads: {},
  allAds: {},
  singleAd: {},
  cities: [],
  modals: [{}],
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CREATE_AD_DATA: {
      return {
        ...state,
        ads: action.payload,
        getCreateAdsDataLoading: false,
      };
    }
    case GET_CITY_DATA: {
      return {
        ...state,
        cities: action.payload,
        listLoading: false,
      };
    }
    case GET_MODALS_DATA: {
      return {
        ...state,
        modals: action.payload,
        listLoading: false,
      };
    }
    case CREATE_NEW_AD_FAIL: {
      return {
        ...state,
        listLoading: false,
      };
    }
    case GET_ALL_ADS: {
      return {
        ...state,
        allAds: action.payload,
        listLoading: false,
        actionLoading: false,
      };
    }
    case SET_ADS_LOADING: {
      return {
        ...state,
        actionLoading: action.payload,
      };
    }
    case GET_SINGLE_AD: {
      return {
        ...state,
        singleAd: action.payload,
        listLoading: false,
      };
    }
    case DELETE_AD_SUCCESS: {
      return {
        ...state,
        allAds: action.payload,
        deleteAdLoading: false,
      };
    }
    case DELETE_AD_LOADER: {
      return {
        ...state,
        deleteAdLoading: true,
      };
    }

    case SET_PRODUCT_LOADING: {
      return {
        ...state,
        listLoading: true,
      };
    }
    case SET_GET_CREATE_DATA_LOADING: {
      return {
        ...state,
        getCreateAdsDataLoading: true,
      };
    }
    default:
      return state;
  }
};
