import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
const resources = {
  en: {
    translation: {
      title: "Find your mobile in seconds.",
    },
  },
  sp: {
    translation: {
      title:
        "react-i18next es un poderoso marco de internacionalización   para React / React Native que se basa en i18next.",
    },
  },
  ur: {
    translation: {
      title: "اپنی مصنوعات کو ایک سیکنڈ میں فروخت کریں۔",
    },
  },
  fr: {
    translation: {
      title:
        "react-i18next est un puissant framework d'internationalisation pour React / React Native basé sur i18next.",
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", //set default language
    fallbackLng: "sp", // fallback language in case of some error occur s
    keySeparator: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
