export const UserRegisterTypes = {
  USER_REGISTER_REQUEST: "USER_REGISTER_REQUEST",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_REGISTER_FAIL: "USER_REGISTER_FAIL",
};
export const GUEST_USER_REGISTER_SUCCESS = "GUEST_USER_REGISTER_SUCCESS";
export const GUEST_USER_REGISTER_LOADER = "GUEST_USER_REGISTER_LOADER";
export const UserLoginTypes = {
  USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAIL: "USER_LOGIN_FAIL",
  USER_LOGOUT: "USER_LOGOUT",
};
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const changePasswordTypes = {
  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAIL: "CHANGE_PASSWORD_FAIL",
  CHANGE_PASSWORD_RESET: "CHANGE_PASSWORD_RESET",
};
// Products
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const GET_SINGLE_PRODUCT = "GET_SINGLE_PRODUCT";

// Home Page
export const HOMEPAGE_DATA_SUCCESS = "HOMEPAGE_DATA_SUCCESS";

export const SHOP_DATA_SUCCESS = "SHOP_DATA_SUCCESS";
export const HOMEPAGE_PRODUCTS_SUCCESS = "HOMEPAGE_PRODUCTS_SUCCESS";

// General
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";
export const SET_LOGIN_LOADER = "SET_LOGIN_LOADER";
export const SET_REGISTER_LOADER = "SET_REGISTER_LOADER";
export const SET_PRODUCT_LOADING = "SET_PRODUCT_LOADING";
export const GET_CITY_DATA = "GET_CITY_DATA";
export const FORGET_PASSWORD_SEND_EMAIL = "FORGET_PASSWORD_SEND_EMAIL";
export const OPT_VERIFICATION = "OPT_VERIFICATION";
export const CONTACT_US = "CONTACT_US";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const CREATE_NEW_AD_LOADER = "CREATE_NEW_AD_LOADER";
export const DELETE_AD_LOADER = "DELETE_AD_LOADER";

// Ads
export const GET_ALL_ADS = "GET_ALL_ADS";
export const GET_SINGLE_AD = "GET_SINGLE_AD";
export const CREATE_NEW_AD = "CREATE_NEW_ADD";
export const CREATE_NEW_AD_FAIL = "CREATE_NEW_AD_FAIL";
export const GET_CREATE_AD_DATA = "GET_CREATE_AD_DATA";
export const GET_MODALS_DATA = "GET_MODALS_DATA";
export const DELETE_AD_SUCCESS = "DELETE_AD_SUCCESS";
export const DELETE_AD_FAIL = "DELETE_AD_FAIL";
export const UPDATE_AD_SUCCESS = "UPDATE_AD_SUCCESS";
export const UPDATE_AD_FAIL = "UPDATE_AD_FAIL";
export const SET_ADS_LOADING = "SET_ADS_LOADING";
export const SET_GET_CREATE_DATA_LOADING = "SET_GET_CREATE_DATA_LOADING";

// Profile
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const SUBSCRIBE_EMAIL = "SUBSCRIBE_EMAIL";
export const EMAIL_VERIFICATION = "EMAIL_VERIFICATION";
export const SET_GUEST_USER_LOADING = "SET_GUEST_USER_LOADING";
